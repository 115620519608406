// src/locales/en.js
const strings = {
    userId: 'User ID',
    botCharacters: 'Bot Characters',
    requestABot: 'Request a Bot',
    botName: 'Bot Name',
    description: 'Description',
    uploadImage: 'Upload Image',
    submitRequest: 'Submit Request',
    submitting: 'Submitting...',
    submissionSuccess: 'Your bot request has been submitted!',
    submissionError: 'Error submitting your request. Please try again.',
    language: 'Language',
    linktoLine:'Link to Line',
    nobotsavailable:'No bots available.',
    servicedescription:'Welcome to Tokuru! Here you can find personal agents to talk with about any topic. You can also request your own Tokuru agent, tailored to your preferences.',
    formdescription:'If you\'d like your own personal Tokuru agent, please fill out the form below to make a request. Your agent will be personalized to your needs!',
    thankyou_submission:'Thank you for your submission! Your request has been received, and we will get back to you soon.',
    inquiryFormTitle: 'Inquiry Form',
    nameLabel: 'Name',
    emailLabel: 'Email',
    messageLabel: 'Message',
    submit: 'Submit',
    errorMessage: 'Error',
    inquirySubmitted: 'Inquiry submitted successfully!',
    inquirySubmitFailed: 'Failed to submit inquiry.',
    hideInquiryForm: 'Hide Inquiry Form',
    submitInquiry: 'Submit an Inquiry',
  };
  
  export default strings;
  