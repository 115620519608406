// src/locales/ja.js
const strings = {
    userId: 'ラインID、Xのアカウント名、またはメールアドレス',
    botCharacters: 'Tokuru エージェント',
    requestABot: 'エージェントをリクエストする',
    botName: 'エージェント名',
    description: 'どんなエージェントと話してみたいですか？',
    uploadImage: '画像をアップロード',
    submitRequest: 'リクエストを送信',
    submitting: '送信中...',
    submissionSuccess: 'エージェントリクエストが送信されました！',
    submissionError: 'リクエストの送信中にエラーが発生しました。もう一度お試しください。',
    language: '言語',
    linktoLine: 'LINEの友達に追加',
    nobotsavailable: '利用可能なエージェントはありません。',
    servicedescription: "Tokuru（トクル）へようこそ！ここでは、あらゆるトピックについて話すことができる個人エージェントを見つけることができます。また、あなたの好みに合わせたTokuruエージェントをリクエストすることもできます。",
    formdescription: "こんなエージェントと話してみたい、というリクエストがあれば以下からお願いします！",
    thankyou_submission: "リクエストいただきありがとうございます！近日中に追加されるかもしれません。",
    headerCopy: "Tokuruで、気軽に友達感覚で話してみて！",
    inquiryFormTitle: 'お問い合わせフォーム',
    nameLabel: 'お名前',
    emailLabel: 'メールアドレス',
    messageLabel: 'お問い合わせ内容',
    submit: '送信',
    errorMessage: 'エラー',
    inquirySubmitted: 'お問い合わせが正常に送信されました。数日中にご連絡いたします。',
    inquirySubmitFailed: 'お問い合わせの送信に失敗しました。',
    hideInquiryForm: 'お問い合わせフォームを隠す',
    submitInquiry: 'お問い合わせはこちらから',
  };
  
  export default strings;
  