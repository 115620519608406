// File: InquiryForm.jsx
import React, { useState, useEffect } from 'react';
import { creatInquiryMutation } from '../graphql/mutations';
import '../BotPage.css';
import awsconfig from '../aws-exports';
import { generateClient } from '@aws-amplify/api';
import { graphqlOperation } from '@aws-amplify/api-graphql';

// Import language strings
import enStrings from '../locales/en';
import esStrings from '../locales/es';
import jaStrings from '../locales/ja';

 

// Create Client for GraphQL, with API key set up
const client = generateClient({
  authMode: 'apiKey',
  apiKey: awsconfig.aws_appsync_apiKey,
});

const InquiryForm = () => {

    // Language state
 const [language, setLanguage] = useState('en');
 const [strings, setStrings] = useState(enStrings);

 useEffect(() => {
    // Set default language based on browser settings
    const userLang = navigator.language || navigator.userLanguage;
    if (userLang.startsWith('es')) {
      setLanguage('es');
      setStrings(esStrings);
    } else if (userLang.startsWith('ja')) {
      setLanguage('ja');
      setStrings(jaStrings);
    } else {
      setLanguage('ja');
      setStrings(jaStrings);
    }
  }, []);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [submitted, setSubmitted] = useState(false); // Added state for submission status


  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    try {
      // Prepare the input for the mutation
      const input = {
        name: formData.name,
        email: formData.email,
        message: formData.message,
      };

      // Call the GraphQL mutation to submit the inquiry
      const result = await client.graphql(
        graphqlOperation(creatInquiryMutation, { input })
      );

      console.log('Inquiry submitted:', result);
      // Set the submission status to true
      setSubmitted(true);


      // Clear form fields
      setFormData({
        name: '',
        email: '',
        message: '',
      });
    } catch (err) {
      console.error('Error submitting inquiry:', err);
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="inquiry-form-container">
    <h2 className="section-heading">{strings.inquiryFormTitle}</h2>

    {submitted ? (
      // Display success message
      <p className="success-message">{strings.inquirySubmitted}</p>
    ) : (
      // Display the form
      <form className="inquiry-form" onSubmit={handleSubmit}>
        <div className="form-group">
          <label>{strings.nameLabel}:</label>
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label>{strings.emailLabel}:</label>
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label>{strings.messageLabel}:</label>
          <textarea
            name="message"
            value={formData.message}
            onChange={handleChange}
            required
          />
        </div>
        <button type="submit" disabled={loading}>
          {loading ? strings.submitting : strings.submit}
        </button>
      </form>
    )}

    {error && (
      <p className="error-message">
        {strings.errorMessage}: {error.message}
      </p>
    )}
  </div>
  );
};

export default InquiryForm;
