// src/aws-exports.js
const awsconfig = {
    aws_project_region: process.env.CDK_DEFAULT_REGION, // e.g., 'us-east-1'
    aws_appsync_graphqlEndpoint: process.env.REACT_APP_APPSYNC_GRAPHQL_ENDPOINT,
    aws_appsync_region: process.env.CDK_DEFAULT_REGION,
    aws_appsync_authenticationType: process.env.REACT_APP_APPSYNC_AUTHENTICATIONTYPE,
    aws_appsync_apiKey: process.env.REACT_APP_APPSYNC_API_KEY,
    aws_user_files_s3_bucket: process.env.REACT_APP_USER_FILES_S3_BUCKET,
    aws_user_files_s3_bucket_region: process.env.CDK_DEFAULT_REGION,
  };
  
  export default awsconfig;
  