
// BotPage.jsx
import React, { useState, useEffect } from 'react';
import { listSelectableAssistantsQuery } from '../graphql/queries';
import { createAssistantSubmissionMutation } from '../graphql/mutations';
import { generateClient } from "aws-amplify/api";
import { uploadData } from 'aws-amplify/storage';
import '../BotPage.css';
import InquiryForm from './InquiryForm';


// Import language strings
import enStrings from '../locales/en';
import esStrings from '../locales/es';
import jaStrings from '../locales/ja';
import awsconfig from '../aws-exports';
import { graphqlOperation } from '@aws-amplify/api-graphql';

//Create Client for GraphQL, with API key set up
const client = generateClient(
    {
        authMode: "apiKey",
        apiKey: awsconfig.aws_appsync_apiKey
    }   
)

const BotPage = () => {
  const [agents, setAgents] = useState([]);
  const [formData, setFormData] = useState({
    name: '',
    description: '',
    imageFile: null,
  });
  const [uploading, setUploading] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  // Language state
  const [language, setLanguage] = useState('en');
  const [strings, setStrings] = useState(enStrings);

  // Inquiry form
  const [showInquiryForm, setShowInquiryForm] = useState(false);

  useEffect(() => {
    fetchAgents();
    // Set default language based on browser settings
    const userLang = navigator.language || navigator.userLanguage;
    if (userLang.startsWith('es')) {
      setLanguage('es');
      setStrings(esStrings);
    } else if (userLang.startsWith('ja')) {
      setLanguage('ja');
      setStrings(jaStrings);
    } else {
      setLanguage('en');
      setStrings(enStrings);
    }
    //For now, just support Japanese
    setLanguage('ja');
    setStrings(jaStrings);
  }, []);

  const fetchAgents = async () => {
    try {
      const agentData = await client.graphql({
        query: listSelectableAssistantsQuery,
      }); 
      console.log(agentData);
      const assistantList = agentData.data.listSelectableAssistants;
      setAgents(assistantList);
    } catch (error) {
      console.error('Error fetching Assistants:', error);
    }
  };

  const handleChange = (e) => {
    if (e.target.name === 'imageFile') {
      setFormData({ ...formData, imageFile: e.target.files[0] });
    } else {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  };

  const handleLanguageChange = (e) => {
    const selectedLanguage = e.target.value;
    setLanguage(selectedLanguage);

    switch (selectedLanguage) {
      case 'en':
        setStrings(enStrings);
        break;
      case 'es':
        setStrings(esStrings);
        break;
      case 'ja':
        setStrings(jaStrings);
        break;
      default:
        setStrings(enStrings);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setUploading(true);

    try {
      let imageKey = '';
      if (formData.imageFile) {
        const { name: fileName } = formData.imageFile;
        imageKey = `images/${Date.now()}_${fileName}`;
        await uploadData(imageKey, formData.imageFile, {
          contentType: formData.imageFile.type,
        });
      }

      const submission = {
        name: formData.name,
        instructions: formData.instructions,
        imageURL: imageKey,
        userId: formData.userId
      };

      console.log('Submitting form:', submission);
      await client.graphql(graphqlOperation(createAssistantSubmissionMutation, { input: submission }));

      setFormData({
        name: '',
        instructions: '',
        imageFile: null,
        userId: ''
      });
      setSubmitted(true);
    } catch (error) {
      console.error('Error submitting form:', error);
      alert(strings.submissionError);
    } finally {
      setUploading(false);
    }
  };

  return (
    <div className="bot-page">
      {/* Header and Logo */}
      <header className="header">
        <div className="logo">
          <img src="./TOKURU_logo.png" alt="Tokuru Logo" />
          {/*<h1></h1> */}
        </div>
      </header>

      {/* Language Selector */}
      {/*}
      <div className="language-selector">
        <label>
          {strings.language}:
          <select value={language} onChange={handleLanguageChange}>
            <option value="en">English</option>
            <option value="es">Español</option>
            <option value="ja">日本語</option>
          </select>
        </label>
      </div>
      {*/}

      {/* Service Description */}
      <section className="service-description">
        <p>{strings.servicedescription}</p>
      </section>

      <h1>{strings.botCharacters}</h1>
      <div className="agent-list">
        {Array.isArray(agents) && agents.length > 0 ? (
          agents.map((agent) => (
            <div key={agent.id} className="agent-card">
              <img src={agent.imageURL} alt={agent.name} className="agent-image" />
              <h2>{agent.name}</h2>
              <p>{agent.description}</p>
              <p><a href={agent.lineUrl}>{strings.linktoLine}</a></p>
            </div>
          ))
        ) : (
          <p>{strings.nobotsavailable}</p>
        )}
      </div>

  {/* Request a Tokuru Agent Section */}
  <section className="request-agent">
    {!submitted && (
      <div className="request-description">
        <p>{strings.formdescription}</p>
      </div>
    )}
    {submitted && <p>{strings.thankyou_submission}</p>}
    {!submitted && (
      <form className="bot-form" onSubmit={handleSubmit}>
        <label>
          {strings.description}
          <textarea
            name="instructions"
            value={formData.instructions}
            onChange={handleChange}
            required
          ></textarea>
        </label>
        <button
          className="agent-request-button"
          type="submit" disabled={uploading}>
          {uploading ? strings.submitting : strings.submitRequest}
        </button>
      </form>
    )}
  </section>

     {/* Inquiry form */} 
     <section className="inquiry-section">
     <button
        className="toggle-inquiry-button"
        onClick={() => setShowInquiryForm(!showInquiryForm)}
      >
        {showInquiryForm ? strings.hideInquiryForm : strings.submitInquiry}
      </button>


      {showInquiryForm && (
        <div className="inquiry-form-container">
          <InquiryForm />
        </div>
      )}
      </section>
      {/* Footer */}
      <footer className="footer">
        <p>© 2024 Tokuru. All rights reserved.</p>

        <ul className="social-links">
          <li>
            <a href="https://x.com/tokuru_jp" target="_blank" aria-label="X">
              <i class="fab fa-twitter"></i>
            </a>
          </li>
          {/*
          <li>
            <a href="https://instagram.com/yourprofile" target="_blank" aria-label="Instagram">
              <i class="fab fa-instagram"></i>
            </a>
          </li>
          */}
          {/*<!-- Font Awesome doesn’t have a Threads icon yet, using hashtag instead --> */}
          {/*
          <li>
            <a href="https://threads.net/yourprofile" target="_blank" aria-label="Threads">
              <i class="fas fa-hashtag"></i> 
            </a>
          </li>
          <li>
            <a href="https://linkedin.com/in/yourprofile" target="_blank" aria-label="LinkedIn">
              <i class="fab fa-linkedin"></i>
            </a>
          </li>
          */}
        </ul>
      </footer>
    </div>
  );
};

export default BotPage;
