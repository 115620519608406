// src/graphql/queries.js

export const listSelectableAssistantsQuery = /* GraphQL */ `
  query ListSelectableAssistants {
    listSelectableAssistants {
      selectableAssistantId
      name
      description
      imageURL
      lineUrl
    }
  }
`;

export const getAssistantSubmissionQuery = /* GraphQL */ `
  query GetAssistantSubmission($id: ID!) {
    getAssistantSubmission(id: $id) {
      id
      name
      instructions
      imageURL
    }
  }
`;