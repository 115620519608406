// src/locales/es.js
const strings = {
    userId: 'User ID',
    botCharacters: 'Personajes de Bot',
    requestABot: 'Solicitar un Bot',
    botName: 'Nombre del Bot',
    description: 'Descripción',
    uploadImage: 'Subir Imagen',
    submitRequest: 'Enviar Solicitud',
    submitting: 'Enviando...',
    submissionSuccess: '¡Tu solicitud de bot ha sido enviada!',
    submissionError: 'Error al enviar tu solicitud. Por favor, inténtalo de nuevo.',
    language: 'Idioma',
    linktoLine: 'Enlace a Line',
    nobotsavailable: 'No hay bots disponibles.',
    servicedescription: "¡Bienvenido a Tokuru! Aquí puedes encontrar agentes personales para hablar sobre cualquier tema. También puedes solicitar tu propio agente Tokuru, adaptado a tus preferencias.",
    formdescription: "Si deseas tu propio agente personal Tokuru, completa el formulario a continuación para hacer una solicitud. ¡Tu agente estará personalizado para ti!",
    thankyou_submission: "¡Gracias por tu envío! Hemos recibido tu solicitud y nos pondremos en contacto contigo pronto.",
    inquiryFormTitle: 'Formulario de Consulta',
    nameLabel: 'Nombre',
    emailLabel: 'Correo electrónico',
    messageLabel: 'Mensaje',
    submit: 'Enviar',
    errorMessage: 'Error',
    inquirySubmitted: '¡Consulta enviada con éxito!',
    inquirySubmitFailed: 'No se pudo enviar la consulta.',
    hideInquiryForm: 'Ocultar Formulario de Consulta',
    submitInquiry: 'Enviar una Consulta',
  };
  
  export default strings;
  