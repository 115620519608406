// src/graphql/mutations.js

export const createAssistantSubmissionMutation = /* GraphQL */ `
  mutation CreateAssistantSubmission($input: CreateAssistantSubmissionInput!) {
    createAssistantSubmission(input: $input) {
      submissionId
      name
      instructions
      imageURL
      userId
    }
  }
`;
export const creatInquiryMutation = /* GraphQL */ `
  mutation CreateInquiry($input: CreateInquiryInput!) {
    createInquiry(input: $input) {
      inquiryId
      name
      email
      message
    }
  }
`;
